import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  ReturnItemModel,
  ReturnModel,
  ReturnStatus,
} from "../../Api/Models/return.model";
import { RiderProfileLayoutContextInterface } from "../../Pages/Rider/RiderProfileLayout";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { useLoading } from "../../Context/LoadingProvider";
import { returnService } from "../../Api/Services/ReturnService";
import { LaravelValidationError } from "../../Api/request";
import { riderService } from "../../Api/Services/RiderService";
import { RiderModel } from "../../Api/Models/rider.model";
import ImageInput from "../ImageInput";
import { useTranslation } from "react-i18next";

interface ReturnProps {
  rider: RiderModel;
  updateGears: CallableFunction;
}

const Return: React.FunctionComponent<ReturnProps> = ({
  rider,
  updateGears,
}) => {
  const { startLoading, finnishLoading } = useLoading();
  const [returnData, setReturnData] = useState<ReturnModel | null>(null);
  const originalReturnData = useRef<ReturnModel | null>(null);
  const [images, setImages] = useState<File[]>([]);
  const [clearancePapers, setClearancePapers] = useState<File[]>([]);
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const { showInfo } = useInfoModal();

  const { fetchRider } = useOutletContext<RiderProfileLayoutContextInterface>();

  const fetchReturn = () => {
    startLoading("get-profile-return");
    riderService
      .getReturn(rider.riderId)
      .then((returnData) => {
        originalReturnData.current = { ...returnData };
        returnData.items = returnData.items.map((item) => {
          return {
            ...item,
            savedToDb:
              item.status === ReturnStatus.GOOD ||
              item.status === ReturnStatus.UNUSABLE ||
              item.status === ReturnStatus.MISSING,
          };
        });

        setReturnData({ ...returnData });
      })
      .finally(() => {
        finnishLoading("get-profile-return");
      });
  };

  useEffect(() => {
    fetchReturn();
    //eslint-disable-next-line
  }, []);

  const updateReturnItemStatus = (itemId: number, status: ReturnStatus) => {
    if (returnData) {
      const itemsState = returnData.items;

      let itemToUpdate = itemsState.find((x) => x.id === itemId);

      if (itemToUpdate && status !== itemToUpdate.status) {
        itemToUpdate.status = status;

        setReturnData({ ...returnData, items: itemsState });
      }
    }
  };

  const handleReturnSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!returnData) {
      return;
    }

    if (
      images.length < 1 ||
      (!returnData.items.some((x) => x.status === ReturnStatus.UNKNOWN) &&
        clearancePapers.length < 1)
    ) {
      showInfo(t("Please select an image!"));
      return;
    }

    startLoading("update-return-data");
    let formData = new FormData();
    formData.append("return_id", returnData.id.toString());
    formData.append(`image`, images[0]);
    if (typeof clearancePapers[0] !== "undefined") {
      formData.append(`clearance_papers`, clearancePapers[0]);
    }

    returnData.items.forEach((item, index) => {
      formData.append(`items[${index}][id]`, item.id.toString());
      formData.append(`items[${index}][status]`, item.status.toString());
    });

    returnService
      .update(formData)
      .then((updatedReturnData) => {
        originalReturnData.current = { ...updatedReturnData };
        updatedReturnData.items = updatedReturnData.items.map((item) => {
          return {
            ...item,
            savedToDb:
              item.status === ReturnStatus.GOOD ||
              item.status === ReturnStatus.UNUSABLE ||
              item.status === ReturnStatus.MISSING,
          };
        });
        setReturnData(updatedReturnData);
        fetchRider();
        fetchReturn();
        updateGears();
        showInfo(t("Gear return updated!"));
      })
      .catch((error: LaravelValidationError) => {
        if (error.message) {
          showInfo(error.message);
        }
      })
      .finally(() => {
        finnishLoading("update-return-data");
      });
  };

  if (!returnData) {
    return <></>;
  }

  return (
    <>
      <p className="text-white">{t("Disp.")}&nbsp;&nbsp;{t("New")}&nbsp;&nbsp;{t("Missing")}&nbsp;{t("Reusable")}</p>
      <form className="flex flex-col gap-4px" onSubmit={handleReturnSave}>
        {returnData?.items.map((item, index) => {
          return (
            <div
              className="checkbox checkbox--large items-center mb-2"
              key={index}
            >
              {[
                ReturnStatus.UNUSABLE,
                ReturnStatus.GOOD,
                ReturnStatus.MISSING,
                ReturnStatus.REUSABLE
              ].map((status) => (
                  <button
                      type="button"
                      className={
                        item.status === status
                            ? "button-product-status-returned"
                            : "button-product-status-default"
                      }
                      onClick={() => {
                        if (!item.savedToDb) {
                          updateReturnItemStatus(item.id, status);
                        }
                      }}
                      disabled={item.savedToDb || (status === ReturnStatus.REUSABLE && !item.reusePossible)}
                  >

                    {item.status === status ? (
                        <i className="icon-approve"></i>
                    ) : null}
                  </button>
              ))}

              <div className="nav-card exchange-list__item justify-start items-center">
                <img src={item.productImage} alt="package name" />
                  <p className="font-12 font-bold">
                      {lang === "ar" ? item.productNameAr
                          : lang === "ur" ? item.productNameUr
                              : lang === "ku" ? item.productNameKu
                                  : item.productName}
                      <span
                          className="font-10">- {item.originalAssignDate}-{item.reusePossible}</span></p>
              </div>
            </div>
          );
        })}
          {returnData?.items
              .map((currentItem) => {
            let ogItem: ReturnItemModel | undefined =
              originalReturnData?.current?.items.find(
                (x) => x.id === currentItem.id
              );

            if (!ogItem) {
              return false;
            }

            return (
              currentItem.status !== ogItem.status ||
              currentItem.condition !== ogItem.condition ||
              currentItem.note !== ogItem.note
            );
          })
          .some((x) => x === true) && (
          <>
            <div className="flex justify-between">
              <div
                className="flex align-middle flex-col"
                style={{ maxWidth: "50%" }}
              >
                <label className="text-center text-white">{t("Items")}</label>
                <ImageInput
                  images={images}
                  setImages={setImages}
                  preview={false}
                  key={"asdhg45zt54"}
                />
                {images.length > 0 ? (
                  <div className="flex justify-center relative">
                    <div className="picture-preview">
                      <button
                        className="button--delete"
                        type="button"
                        onClick={() => {
                          let newImages = [...images];
                          newImages.splice(0, 1);
                          setImages(newImages);
                        }}
                      >
                        &times;
                      </button>
                      <img
                        src={URL.createObjectURL(images[0])}
                        alt="Selected file"
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              <div
                className="flex align-middle flex-col"
                style={{ maxWidth: "50%" }}
              >
                <label className="text-center text-white">
                  {t("Clearance paper")}
                </label>
                <ImageInput
                  images={clearancePapers}
                  setImages={setClearancePapers}
                  preview={false}
                  key={"asdhg45"}
                  required={
                    !returnData.items.some(
                      (x) => x.status === ReturnStatus.UNKNOWN
                    )
                  }
                />
                {clearancePapers.length > 0 ? (
                  <div className="flex justify-center relative">
                    <div className="picture-preview">
                      <button
                        className="button--delete"
                        type="button"
                        onClick={() => {
                          let newImages = [...clearancePapers];
                          newImages.splice(0, 1);
                          setClearancePapers(newImages);
                        }}
                      >
                        &times;
                      </button>
                      <img
                        src={URL.createObjectURL(clearancePapers[0])}
                        alt="Selected file"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <button type="submit" className="button button--green w-10">
              {t("Save")}
            </button>
          </>
        )}
      </form>
    </>
  );
};

export default Return;
